import { type Route } from "../core";
import { type EArveldaja } from "./EArveldaja";

export default {
  path: "/e-arveldaja",
  component() {
    return import(/* webpackChunkName: "arveldaja" */ "./EArveldaja");
  },
  drawer: false,
  response: ({ t }) => ({
    title: t("e-arveldaja"),
  }),
} as Route<EArveldaja>;
