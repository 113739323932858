import { AccountBalance, CloudUpload, ReceiptLong } from "@mui/icons-material";
import {
  Drawer,
  DrawerProps,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import * as React from "react";
import { useLanguage, useLocation } from "../core";

type AppDrawerProps = Omit<DrawerProps, "variant" | "children">;

const width = 180;
const items = [
  {
    href: "/accounts",
    label: "Bank Accounts",
    icon: <AccountBalance />,
  },
  {
    href: "/transactions",
    label: "Transactions",
    icon: <ReceiptLong />,
  },
  {
    href: "/import",
    label: "Import",
    icon: <CloudUpload />,
  },
];

function AppDrawer(props: AppDrawerProps): JSX.Element {
  const { sx, ...other } = props;
  const location = useLocation();
  const lng = useLanguage();

  const isSelected = React.useCallback(
    (path: string) =>
      location.pathname === path || location.pathname.startsWith(`${path}/`),
    [location.pathname],
  );

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width,
          zIndex: (theme) => theme.zIndex.appBar - 100,
        },
        ...sx,
      }}
      variant="permanent"
      {...other}
    >
      <Toolbar />
      <List sx={{ my: 2 }}>
        {items.map((x) => (
          <ListItemButton
            key={x.href}
            component={Link}
            href={`${lng.path}${x.href}`}
            selected={isSelected(`${lng.path}${x.href}`)}
          >
            <ListItemIcon
              sx={{
                minWidth: 32,
                "& svg": { fontSize: "1.25rem" },
              }}
              children={x.icon}
            />
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  ".Mui-selected &": { fontWeight: 600 },
                },
              }}
              primary={x.label}
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
}

AppDrawer.width = width;

export { AppDrawer, type AppDrawerProps };
