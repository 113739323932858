/**
 * @generated SignedSource<<ba57389e09266632dd00ab48401cfab1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountsQuery$variables = {};
export type AccountsQuery$data = {
  readonly accounts: ReadonlyArray<{
    readonly id: string;
    readonly iban: string;
    readonly currency: string | null;
    readonly name: string | null;
    readonly institution: {
      readonly id: string;
      readonly name: string;
      readonly logo: string;
    } | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"Auth_me">;
};
export type AccountsQuery = {
  variables: AccountsQuery$variables;
  response: AccountsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iban",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Institution",
      "kind": "LinkedField",
      "name": "institution",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Auth_me"
      },
      (v2/*: any*/)
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Picture",
            "kind": "LinkedField",
            "name": "picture",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "94c85badc542f4f21daae3e93af015e3",
    "id": null,
    "metadata": {},
    "name": "AccountsQuery",
    "operationKind": "query",
    "text": "query AccountsQuery {\n  ...Auth_me\n  accounts {\n    id\n    iban\n    currency\n    name\n    institution {\n      id\n      name\n      logo\n    }\n  }\n}\n\nfragment Auth_me on Root {\n  me {\n    ...Auth_user\n    id\n  }\n}\n\nfragment Auth_user on User {\n  id\n  email\n  name\n  picture {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "84818906b647be24c0ecc7eb40dd15c3";

export default node;
