/**
 * @generated SignedSource<<518d4dc50403d8d4eb7e6988d8ebb142>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionsQuery$variables = {};
export type TransactionsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Auth_me">;
};
export type TransactionsQuery = {
  variables: TransactionsQuery$variables;
  response: TransactionsQuery$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Auth_me"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Picture",
            "kind": "LinkedField",
            "name": "picture",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ada4caa270144b7381bd233109f526d",
    "id": null,
    "metadata": {},
    "name": "TransactionsQuery",
    "operationKind": "query",
    "text": "query TransactionsQuery {\n  ...Auth_me\n}\n\nfragment Auth_me on Root {\n  me {\n    ...Auth_user\n    id\n  }\n}\n\nfragment Auth_user on User {\n  id\n  email\n  name\n  picture {\n    url\n  }\n}\n"
  }
};

(node as any).hash = "d524cd96437fd1215dd935a230ee97a5";

export default node;
