import { Button, ButtonProps } from "@mui/material";
import { LoginMethod, useAuth } from "../core";
import * as React from "react";
import { Google } from "../icons";
import { Facebook } from "@mui/icons-material";

type LoginButtonProps = Omit<ButtonProps, "children"> & {
  method: LoginMethod;
};

export function LoginButton(props: LoginButtonProps): JSX.Element {
  const { sx, method, ...other } = props;
  const auth = useAuth();

  const signIn = React.useCallback(function signIn(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    event.preventDefault();
    const method = event.currentTarget.dataset.method as LoginMethod;
    auth.signIn({ method });
  },
  []);

  return (
    <Button
      sx={{ textTransform: "none", ...sx }}
      variant="outlined"
      size="large"
      href={`/auth/${method.toLowerCase()}`}
      startIcon={
        method === "Google" ? (
          <Google />
        ) : method === "Facebook" ? (
          <Facebook />
        ) : null
      }
      data-method={method}
      fullWidth
      onClick={signIn}
      {...other}
    >
      <span style={{ flexGrow: 1, textAlign: "center" }}>
        Continue with {method}
      </span>
    </Button>
  );
}
