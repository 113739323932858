/* SPDX-FileCopyrightText: 2016-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import { graphql } from "relay-runtime";
import { type Route } from "../core";
import { type SetupQuery } from "../queries/SetupQuery.graphql";
import { type Setup } from "./Setup";

/**
 * User account settings route.
 */
export default {
  path: "/setup",
  query: graphql`
    query SetupQuery {
      me {
        ...Auth_user
        id
        email
        name
      }
    }
  `,
  drawer: false,
  authorize: true,
  component() {
    return import(/* webpackChunkName: "setup" */ "./Setup");
  },
  response: ({ data, t }) => ({
    title: t("Setup"),
    props: data,
  }),
} as Route<Setup, SetupQuery>;
