/* SPDX-FileCopyrightText: 2016-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import { Box, CssBaseline, PaletteMode, Toolbar } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { Action, Update } from "history";
import * as React from "react";
import { Environment, RelayEnvironmentProvider } from "react-relay";
import type { Config } from "../config";
import {
  AuthProvider,
  ConfigContext,
  getI18n,
  LanguageProvider,
} from "../core";
import { History, HistoryContext, LocationContext } from "../core/history";
import type { RouteResponse } from "../core/router";
import { resolveRoute } from "../core/router";
import { ThemeProvider } from "../theme";
import { AppDrawer } from "./AppDrawer";
import { AppToolbar } from "./AppToolbar";
import { ErrorPage } from "./ErrorPage";

type AppProps = {
  config: Config;
  history: History;
  relay: Environment;
};

const i18nPromise = getI18n();

class App extends React.Component<AppProps> {
  state = {
    route: undefined as RouteResponse | undefined,
    location: this.props.history.location,
    error: undefined as Error | undefined,
    theme: (window?.localStorage?.getItem("theme") === "dark"
      ? "dark"
      : "light") as PaletteMode,
  };

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  dispose?: () => void;

  componentDidMount(): void {
    const { history } = this.props;
    this.dispose = history.listen(this.renderPath);
    this.renderPath({ location: history.location, action: Action.Pop });
  }

  componentDidUpdate(): void {
    if (this.state.route?.title) {
      self.document.title = this.state.route.title;
    }
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose();
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  renderPath = async (ctx: Update): Promise<void> => {
    resolveRoute({
      hostname: window.location.hostname,
      path: ctx.location.pathname,
      query: new URLSearchParams(ctx.location.search),
      search: ctx.location.search,
      relay: this.props.relay,
      i18n: await i18nPromise,
    }).then((route) => {
      if (route.error) console.error(route.error);
      this.setState({ route, location: ctx.location, error: route.error });
    });
  };

  render(): JSX.Element {
    const { config, history } = this.props;
    const { route, location, error } = this.state;

    if (error) {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <ErrorPage error={error} history={history} />;
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }

    const hasDrawer = route && route.drawer !== false ? true : false;
    const component = route?.component
      ? React.createElement(route.component, route.props)
      : null;

    return (
      <ConfigContext.Provider value={config}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <RelayEnvironmentProvider environment={this.props.relay}>
              <LanguageProvider value={route?.lng}>
                <AuthProvider>
                  <HistoryContext.Provider value={history}>
                    <LocationContext.Provider value={location}>
                      <CssBaseline />
                      <AppToolbar />
                      <Box
                        sx={{
                          display: "flex",
                          ml: hasDrawer ? `${AppDrawer.width}px` : undefined,
                        }}
                      >
                        {hasDrawer ? <AppDrawer /> : null}
                        <Box
                          sx={{
                            flexGrow: 1,
                            minHeight: "100vh",
                            ...(hasDrawer && {
                              display: "flex",
                              flexDirection: "column",
                            }),
                          }}
                        >
                          <Toolbar />
                          {component}
                        </Box>
                      </Box>
                    </LocationContext.Provider>
                  </HistoryContext.Provider>
                </AuthProvider>
              </LanguageProvider>
            </RelayEnvironmentProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ConfigContext.Provider>
    );
  }
}

export { App };
