/* SPDX-FileCopyrightText: 2016-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import {
  match as createMatchFn,
  type Match,
  type MatchFunction,
} from "path-to-regexp";
import { fetchQuery } from "react-relay";
import { getRequest } from "relay-runtime";
import { canUseDOM, type Lang } from "../core";
import routes from "../routes";
import { getCurrentUser } from "./Auth";
import { ForbiddenError, NotFoundError } from "./errors";
import {
  type Route,
  type RouterContext,
  type RouterResponse,
} from "./router.types";

/**
 * Converts the URL path string to a RegExp matching function.
 *
 * @see https://github.com/pillarjs/path-to-regexp
 */
const matchUrlPath: (
  pattern: string[] | string,
  path: string,
) => Match<{ [key: string]: string }> = (() => {
  const cache = new Map<string, MatchFunction<{ [key: string]: string }>>();
  return function matchUrlPath(pattern: string[] | string, path: string) {
    const key = Array.isArray(pattern) ? pattern.join("::") : pattern;
    let fn = cache.get(key);
    if (fn) return fn(path);
    fn = createMatchFn(pattern, { decode: decodeURIComponent });
    cache.set(key, fn);
    return fn(path);
  };
})();

async function resolveRoute(ctx: RouterContext): Promise<RouterResponse> {
  const [path, lng, lngRedirect] = resolveLanguage(ctx);

  if (lngRedirect) {
    return {
      redirect: `${lngRedirect}${ctx.search ?? ""}`,
      headers: { "Set-Cookie": `lng=${lng}; Max-Age=31536000` },
      lng,
    };
  }

  try {
    // Find the first route matching the provided URL path string
    for (let i = 0, route; i < routes.length, (route = routes[i]); i++) {
      const match = matchUrlPath(route.path, path);

      if (!match) continue;

      ctx.params = match.params;

      // Prepare GraphQL query variables
      const variables =
        typeof route.variables === "function"
          ? route.variables(ctx)
          : route.variables
          ? route.variables
          : Object.keys(match.params).length === 0
          ? {}
          : match.params;

      // If `auth` variable is present in the route's GraphQL query
      // and the user's authentication state is not known yet, set it to true.
      if (route.query) {
        const { operation } = getRequest(route.query);
        if (operation.argumentDefinitions.some((x) => x.name === "auth")) {
          variables.auth = getCurrentUser(ctx.relay) === undefined;
        }
      }

      const [component, data] = await Promise.all([
        // Download the missing JavaScript application chunks(s) if any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        route.component?.().then((x: any) => x.default),
        // Fetch data from the API if required by the route
        route.query &&
          fetchQuery(ctx.relay, route.query, variables, {
            fetchPolicy: "store-or-network",
          }).toPromise(),
        // Download the missing i18n locales if any
        // NOTE: In the Cloudflare Worker environment, all the "routes" locales
        // are pre-loaded, no need to download them.
        (ctx.i18n.language === lng
          ? Promise.resolve()
          : (ctx.i18n.changeLanguage(lng) as Promise<unknown>)
        ).then(() =>
          canUseDOM
            ? ctx.i18n.loadNamespaces(["routes", "common"])
            : Promise.resolve(),
        ),
      ]);

      // Check if the route requires an authenticated user
      if (route.authorize) {
        const user = getCurrentUser(ctx.relay);
        if (
          !user ||
          (typeof route.authorize === "function" && !route.authorize(user))
        ) {
          throw new ForbiddenError();
        }
      }

      const response = route.response({
        ...ctx,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        data: data as any,
        t: ctx.i18n.getFixedT(lng, "routes"),
      });

      if (response) {
        return {
          component,
          ...response,
          lng,
          drawer: response.drawer ?? route.drawer,
        };
      }
    }

    throw new NotFoundError();
  } catch (err) {
    return {
      title:
        err instanceof NotFoundError ? "Page not found" : "Application error",
      error: err as Error,
      lng,
    };
  }
}

/**
 * Extracts the language (code) from the URL pathname.
 *
 * @example
 *   "/about" => ["/about", "de", "/de/about"]
 *   "/de/about" => ["/about", "de", undefined]
 */
function resolveLanguage(
  ctx: RouterContext,
): [path: string, lng: Lang, redirect: string | false] {
  if (!ctx.i18n.options.supportedLngs) throw new Error();

  // Use Estonian for "arvelda.ee" and English for "arvelda.com"
  // as the default language.
  const defaultLng: Lang = ctx.hostname.endsWith(".ee") ? "et" : "en";
  const supportedLngs = ctx.i18n.options.supportedLngs.filter(
    (lng) => lng !== "cimode",
  );

  let path = ctx.path;
  let pathLng: Lang | undefined;

  // Check if the current URL pathname starts with the `/{lng}` prefix
  for (const lng of supportedLngs) {
    if (lng === defaultLng) continue;

    if (ctx.path === `/${lng}`) {
      path = "/";
      pathLng = lng as Lang;
      break;
    }

    if (ctx.path.startsWith(`/${lng}/`)) {
      path = ctx.path.substring(lng.length + 1) || "/";
      pathLng = lng as Lang;
      break;
    }
  }

  // Resolves the preferred language (code) using the following order:
  //   1. The URL pathname, e.g. "/de/about" => "de"
  //   2. The `Cookie` header, e.g. "lng=de" => "de"
  //   3. The `Accept-Language` header, e.g. "de-DE, de:q=0.8; *;q=0.5" => "de"
  //   3. TLD, e.g "arvelda.com" => "en", "arvelda.ee" => "et"
  const lng: Lang =
    pathLng ||
    (ctx.cookie?.lng &&
      supportedLngs.includes(ctx.cookie.lng) &&
      (ctx.cookie.lng as Lang)) ||
    (ctx.acceptLanguages
      ?.map((lng) => lng.substring(0, 2))
      .find((lng) => supportedLngs.includes(lng)) as Lang) ||
    defaultLng;

  // Require a redirect when both of these conditions are met:
  //  1. URL pathname doesn't include a language code
  //  2. The resolved (detected) language differs from the default value
  const redirect =
    !pathLng && lng !== defaultLng
      ? `/${lng}${path === "/" ? "" : path}`
      : false;

  return [path, lng, redirect];
}

export {
  resolveRoute,
  type Route,
  type RouterContext,
  type RouterResponse as RouteResponse,
};
