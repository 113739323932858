import { graphql } from "relay-runtime";
import { type Route } from "../core";
import { type Import } from "./Import";

export default {
  path: ["/import", "/imports"],
  query: graphql`
    query ImportQuery {
      ...Auth_me
    }
  `,
  authorize: true,
  component() {
    return import(/* webpackChunkName: "import" */ "./Import");
  },
  response({ t, path }) {
    return path === "/imports"
      ? { redirect: "/import" }
      : {
          title: t("Import Transactions"),
        };
  },
} as Route<Import>;
