import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import memoize from "lodash/memoize";
import { initReactI18next } from "react-i18next";

type Lang = "en" | "et" | "de" | "ru";

const supportedLngs: Lang[] = ["et", "en", "de", "ru"];

const [lng, defaultLng] = (() => {
  // Attempt to resolve the initial language code from the `<html lang="??">` tag
  // generated on the server-side using Cloudflare Workers.
  const lng = document.documentElement.getAttribute("lang")?.substring(0, 2);
  const defaultLng = location.hostname.endsWith(".ee") ? "et" : "en";
  if (lng) return [lng as Lang, defaultLng as Lang];

  // Otherwise, resolve the initial language code from the URL pathname.
  // NOTE: This is is only relevant for local development.
  const path = window.location.pathname;

  for (const lng of supportedLngs.filter((lng) => lng !== defaultLng)) {
    if (path === `/${lng}` || path.startsWith(`/${lng}/`)) {
      return [lng as Lang, defaultLng as Lang];
    }
  }

  return [defaultLng as Lang, defaultLng as Lang];
})();

/**
 * @example
 *   translateURLPath("/about", "ru") => "/ru/about"
 *   translateURLPath("/de/about", "ru") => "/ru/about"
 */
function translateURLPath(path: string, lng: string): string {
  if (!supportedLngs.includes(lng as Lang)) throw new Error();

  for (const lang of supportedLngs.filter((lng) => lng !== defaultLng)) {
    if (path === `/${lang}`) {
      return lng === defaultLng ? "/" : `/${lng}`;
    }

    if (path.startsWith(`/${lang}/`)) {
      return lng === defaultLng
        ? path.substring(3)
        : `/${lng}${path.substring(3)}`;
    }
  }

  return lng === defaultLng ? path : `/${lng}${path === "/" ? "" : path}`;
}

/**
 * Initializes the i18next instance.
 *
 * Requirements:
 *   - Use English key values as fallback
 *   - Prevent downloading English locales
 *   - Use `common` as the default namespace
 */
const getI18n = memoize(function getI18n() {
  return i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      resources: {
        en: { routes: {}, common: {} },
      },
      lng,
      fallbackLng: false,
      supportedLngs,
      load: "languageOnly",
      returnEmptyString: false,
      ns: "common",
      defaultNS: "common",
      partialBundledLanguages: true,
      nsSeparator: false,
      keySeparator: false,
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false,
      },
    })
    .then(() => i18n);
});

export { getI18n, translateURLPath, lng, defaultLng, type Lang };
