import * as React from "react";
import { defaultLng, lng, type Lang } from "./i18n";

type Language = {
  /** The language code */
  code: Lang;
  /** The URL path prefix */
  path: string;
};

type LanguageProviderProps = {
  children: React.ReactNode;
  value?: Lang | null;
};

const LanguageContext = React.createContext<Lang>(lng);

/**
 * Returns the currently selected language code and the URL base path.
 *
 * @example
 *    const lng = useLanguage(); // { code: "ru", path: "/ru" }
 */
function useLanguage(): Language {
  const lng = React.useContext(LanguageContext);
  return React.useMemo(
    () => ({
      code: lng,
      path: lng === defaultLng ? "" : `/${lng}`,
    }),
    [lng],
  );
}

function LanguageProvider(props: LanguageProviderProps): JSX.Element {
  return (
    <LanguageContext.Provider
      value={props.value || lng}
      children={props.children}
    />
  );
}

export { LanguageProvider, useLanguage };
