import { graphql } from "relay-runtime";
import { type Route } from "../core";
import { type AccountsQuery } from "../queries/AccountsQuery.graphql";
import { type Accounts } from "./Accounts";

export default {
  path: "/accounts",
  query: graphql`
    query AccountsQuery {
      ...Auth_me
      accounts {
        id
        iban
        currency
        name
        institution {
          id
          name
          logo
        }
      }
    }
  `,
  authorize: true,
  component() {
    return import(/* webpackChunkName: "accounts" */ "./Accounts");
  },
  response: ({ t, data }) => ({
    title: t("Banks Accounts"),
    props: data,
  }),
} as Route<Accounts, AccountsQuery>;
