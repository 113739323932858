import { graphql } from "relay-runtime";
import { type Route } from "../core";
import { type Transactions } from "./Transactions";

export default {
  path: "/transactions",
  query: graphql`
    query TransactionsQuery {
      ...Auth_me
    }
  `,
  authorize: true,
  component() {
    return import(/* webpackChunkName: "transactions" */ "./Transactions");
  },
  response: ({ t }) => ({
    title: t("Transactions"),
  }),
} as Route<Transactions>;
