/* SPDX-FileCopyrightText: 2016-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import accounts from "./Accounts.route";
import accountSettings from "./AccountSettings.route";
import earveldaja from "./EArveldaja.route";
import home from "./Home.route";
import imports from "./Import.route";
import legal from "./Legal.route";
import setup from "./Setup.route";
import transactions from "./Transactions.route";

/**
 * The list of application routes (pages).
 */
export default [
  home,
  accountSettings,
  ...legal,
  setup,
  accounts,
  transactions,
  imports,
  earveldaja,
] as const;
