import { ArrowDropDown, Translate } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import * as React from "react";
import { useLanguage } from "../core";
import { LanguageMenu } from "../menus";

type Menu = { anchorEl?: Element; time?: number };
type LanguageButtonProps = Omit<ButtonProps, "children">;

function LanguageButton(props: LanguageButtonProps): JSX.Element {
  const { sx, ...other } = props;
  const [menu, setMenu] = React.useState<Menu>({});
  const lng = useLanguage();

  const open = React.useCallback(function (event: React.MouseEvent) {
    event.preventDefault();
    setMenu({ anchorEl: event.currentTarget, time: Date.now() });
  }, []);

  const close = React.useCallback(function () {
    // Close the menu if it was opened more than 500ms ago (touchscreen fix)
    setMenu((prev) => (prev.time && prev.time < Date.now() - 500 ? {} : prev));
  }, []);

  return (
    <React.Fragment>
      <Button
        sx={{ "& .MuiButton-endIcon": { ml: 0 }, ...sx }}
        color="inherit"
        startIcon={<Translate />}
        endIcon={<ArrowDropDown />}
        children={lng.code.toUpperCase()}
        onClick={open}
        onMouseOver={open}
        {...other}
      />
      <LanguageMenu anchorEl={menu.anchorEl} onClose={close} />
    </React.Fragment>
  );
}

export { LanguageButton, type LanguageButtonProps };
