import { Link, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material";
import Cookie from "js-cookie";
import * as React from "react";
import {
  translateURLPath,
  useHistory,
  useLanguage,
  useLocation,
} from "../core";

const languages = [
  { lng: "et", name: "Eesti" },
  { lng: "en", name: "English" },
  { lng: "de", name: "Deutsch" },
  { lng: "ru", name: "Русский" },
];

type LanguageMenuProps = Omit<
  MenuProps,
  | "id"
  | "role"
  | "open"
  | "getContentAnchorEl"
  | "anchorOrigin"
  | "transformOrigin"
>;

function LanguageMenu(props: LanguageMenuProps): JSX.Element {
  const { PaperProps, MenuListProps, ...other } = props;
  const location = useLocation();
  const history = useHistory();
  const { code: currentLng } = useLanguage();

  const handleClick = React.useCallback(
    function (event: React.MouseEvent<HTMLAnchorElement>) {
      event.preventDefault();
      props.onClose?.(event, "backdropClick");
      const lng = event.currentTarget.dataset.lng as string;
      // Save the selected language in cookies
      // to be used during SSR (in Cloudflare Workers) and
      // the initial language detection.
      Cookie.set("lng", lng, { expires: 365 });
      // Append the selected language to the URL pathname
      const pathname = translateURLPath(location.pathname, lng);
      const { search, state } = location;
      history.replace({ pathname, search }, state);
    },
    [location.pathname],
  );

  const onMouseLeave = React.useCallback(function (event: React.MouseEvent) {
    props.onClose?.(event, "backdropClick");
  }, []);

  return (
    <Menu
      id="language-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ ...PaperProps, sx: { ...PaperProps?.sx, width: 120 } }}
      MenuListProps={{ ...MenuListProps, dense: true, onMouseLeave }}
      {...other}
      children={languages.map(({ lng, name }) => (
        <MenuItem
          key={lng}
          component={Link}
          href={`${translateURLPath(location.pathname, lng)}${location.search}`}
          data-lng={lng}
          onClick={handleClick}
          children={
            <ListItemText
              primaryTypographyProps={{
                sx: { fontWeight: lng === currentLng ? 600 : undefined },
              }}
              primary={name}
            />
          }
        />
      ))}
    />
  );
}

export { LanguageMenu, type LanguageMenuProps };
