/* SPDX-FileCopyrightText: 2016-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import { Close } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Typography,
} from "@mui/material";
import * as React from "react";
import { LoginButton } from "../common/LoginButton";

type LoginDialogProps = Omit<DialogProps, "children"> & {
  error?: string;
};

function LoginDialog(props: LoginDialogProps): JSX.Element {
  const { error, ...other } = props;

  const close = React.useCallback(function close(event: React.MouseEvent) {
    event.preventDefault();
    props.onClose?.(event, "backdropClick");
  }, []);

  return (
    <Dialog scroll="body" {...other}>
      <IconButton
        sx={{ position: "fixed", top: "8px", right: "8px" }}
        onClick={close}
        children={<Close />}
        size="large"
      />

      <DialogContent
        sx={{
          maxWidth: "320px",
          margin: "0 auto",
          "& .MuiButton-root:not(:last-of-type)": {
            marginBottom: "1rem",
          },
        }}
      >
        {/* Title */}
        <Typography
          sx={{ marginTop: "25vh", marginBottom: "1rem" }}
          variant="h3"
          align="center"
          children="Log in / Register"
        />

        {/* Error message */}
        {error && <Alert sx={{ mb: 3 }} severity="error" children={error} />}

        {/* Login buttons */}
        <LoginButton method="Google" />
        <LoginButton method="Facebook" />
      </DialogContent>
    </Dialog>
  );
}

export { LoginDialog };
